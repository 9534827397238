<template>
  <el-card shadow="hover" header="" class="x-card-title-slot">
    <div class="row no-gutters align-items-center" slot="header">
      <div class="col header-title">
        站点信息
      </div>

      <el-button type="primary" @click="handleSaveShopData('ruleForm')" size="small">
        保存
      </el-button>
    </div>

    <el-form :model="ruleForm" ref="ruleForm" label-width="150px" class="demo-ruleForm" :rules="rules" size="small">
      <el-form-item label="站点名称" prop="shop_name">
        <el-input v-model="ruleForm.shop_name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="创建时间">
        <span>
          {{ ruleForm.shop_createtime | unixToDate('yyyy-MM-dd hh:mm') }}
        </span>
      </el-form-item>
      <el-form-item label="客服电话" prop="customer_service">
        <el-input v-model="ruleForm.customer_service" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="是否显示客服电话" prop="show_customer_service">
        <el-switch v-model="ruleForm.show_customer_service"></el-switch>
      </el-form-item>
      <el-form-item label="商城状态" prop="shop_disable">
        <el-switch v-model="ruleForm.shop_disable" :disabled="false"></el-switch>
      </el-form-item>
      <!-- <el-form-item label="是否开通在线客服" prop="show_purchasing_notice_switch">
        <el-switch v-model="ruleForm.customer_service_show" style="margin-right:40px"></el-switch>提示：开通后，商城将显示“在线客服”按钮，并跳转至指定客服链接。
      </el-form-item>
      <el-form-item v-show="ruleForm.customer_service_show" label="客服跳转链接" prop="customer_service_link">
        <el-input v-model="ruleForm.customer_service_link" style="width: 300px"></el-input>
      </el-form-item> -->
      <el-form-item label="公告信息" prop="notice">
        <el-input v-model="ruleForm.notice" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="登录页图片显示" prop="login_picture">
        <el-upload class="site-logo" :action="MixinUploadApi" :show-file-list="false" v-model="ruleForm.login_picture"
          :before-upload="beforeUpload" :on-success="uploadSuccess" :multiple="false">
          <img v-if="ruleForm.login_picture" :src="ruleForm.login_picture" class="site-logo-img" />
          <i v-else class="el-icon-plus logo-uploader-icon"></i>
        </el-upload>
        <span v-if="!ruleForm.login_picture" style="color: #999999">建议图片尺寸比例为4:3，大小不超5MB，只允许上传1张格式为jpg/png的图片。</span>
      </el-form-item>
      <el-form-item label="网页版权声明" prop="copyright">
        <el-input v-model="ruleForm.copyright" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="" prop="name">
        <div style="line-height: 1.7">
          <span>此信息将显示在网页底部</span><br />
          <span style="padding-top: 15px; color: gray" v-if="ruleForm.copyright">
            {{ ruleForm.copyright }}
          </span>
          <span style="padding-top: 15px; color: gray" v-else>
            例：@ 2019 fqifu.com。版权所有北京锋采企服网络技术有限公司
          </span>
        </div>
      </el-form-item>
      <el-form-item label="商城备注信息">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 7 }" maxlength="100" :rows="2"
          placeholder="请输入100字以内的备注信息" v-model="ruleForm.shop_remark" style="width: 300px"></el-input>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import * as API_account from '@/api/account';

export default {
  name: 'person',
  data () {
    return {
      ruleForm: {},
      params: {
        shop_id: '',
        shop_name: '',
        customer_service: '',
        show_mktprice: '',
        enable_aftersale: '',
        shop_disable: '',
        show_customer_service: '', // 是否展示客服电话
        copyright: '',
        monetary_unit: '',
        login_picture: '', // 登录页图片展示
        notice: '', //公告信息
        shop_remark: ''
      },
      rules: {
        shop_name: [
          {
            required: true,
            message: '请输入站点名称',
            trigger: 'blur'
          },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        customer_service: [
          {
            // required: true,

            validator: (rule, value, callback) => {
              // let reg = /^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/;
              // if (value && !reg.test(value)) {
              //   return callback('座机号或手机号格式不对');
              // }
              callback();
            },
            trigger: "blur"
          }
        ],
        copyright: [
          {
            required: true,
            message: '请输入网页版权声明',
            trigger: 'blur'
          },
        ],
        monetary_unit: [
          {
            min: 1,
            max: 2,
            message: '字数不超2个字符',
            trigger: 'blur'
          }
        ],
        customer_service_link: [
          {
            required: true,
            message: '开通在线客服功能，必须要填写客服跳转链接',
            trigger: 'blur'
          },
        ]
      },
      // 图片url
      imgUrl: ''
    };
  },
  mounted () {
  },
  created () {
    this.getShopMsg();
  },
  computed: {},
  methods: {
    // 上传图片
    beforeUpload (file) {
      let file_type = file.type.indexOf('jpg') > -1 || file.type.indexOf('png') > -1 || file.type.indexOf('jpeg') > -1
      if (!file_type) {
        this.$message.error('请上传jpg，png格式的图片')
      }
      if (file_type) {
        if (file.size > 1024 * 1024 * 5) {
          this.$message.error('图片大小不得超过5MB')
          file_type = false
        }
      }
      return file_type
    },
    uploadSuccess (res) {
      if (res.url) {
        this.params.login_picture = res.url
        this.ruleForm.login_picture = res.url
      }
    },
    handleSaveShopData (formName) {
      console.log(this.params);
      console.log(this.ruleForm)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.params.shop_id = this.ruleForm.shop_id;
          this.params.shop_name = this.ruleForm.shop_name;
          this.params.customer_service = this.ruleForm.customer_service;
          this.params.show_mktprice = this.ruleForm.show_mktprice ? 1 : 0;
          this.params.enable_aftersale = this.ruleForm.enable_aftersale ? 1 : 0;
          this.params.shop_disable = this.ruleForm.shop_disable ? 'OPEN' : 'CLOSED';
          this.params.copyright = this.ruleForm.copyright;
          this.params.customer_service_show = this.ruleForm.customer_service_show ? 1 : 0;
          this.params.customer_service_link = this.ruleForm.customer_service_link;
          this.params.monetary_unit = this.ruleForm.monetary_unit;
          this.params.notice = this.ruleForm.notice;
          this.params.shop_remark = this.ruleForm.shop_remark;
          this.params.show_customer_service = this.ruleForm.show_customer_service ? 1 : 0;
          API_account.editShopSetting(this.params).then((res) => {
            this.getShopMsg();
            this.$message.success('操作成功');
          });
        } else {
          return false;
        }
      });
    },
    getShopMsg () {
      API_account.getShopmsg().then((res) => {
        this.ruleForm = res;
        console.log(res)
        this.ruleForm.show_mktprice = this.ruleForm.show_mktprice === 1;
        this.ruleForm.enable_aftersale = this.ruleForm.enable_aftersale === 1;
        this.ruleForm.shop_disable = this.ruleForm.shop_disable === 'OPEN';
        this.ruleForm.show_customer_service = this.ruleForm.show_customer_service === 1;
        this.ruleForm.customer_service_show = this.ruleForm.customer_service_show === 1;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .show {
  display: none;
}

/* /deep/ .el-input__inner {
  width: 250px !important;
}
/deep/.el-form-item__content .el-input-group {
  .el-input__inner {
    width: 110px !important;
  }
  .el-input-group__prepend {
    width: 140px !important;
  }
}
/deep/.el-form-item__content .el-input-group {
  width: 250px !important;
} */
.content {
  background-color: white;
  border-radius: 10px;
  padding-bottom: 20px;
}

/deep/ .el-form-item {
  margin-bottom: 15px;
}

.zz {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.shu {
  height: 30px;
  width: 6px;
  background-color: rgb(26, 67, 169);
  display: inline-block;
}

.he {
  padding-top: 30px;
  padding-bottom: 30px;
}

.site-logo-img {
  width: 150px;
  height: 150px;
  display: block;
}
</style>
